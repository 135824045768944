import React from "react";
import Container from "./container";
import { imageUrlFor } from "../lib/image-url";
import { buildImageObj } from "../lib/helpers";
import { cn } from "../lib/helpers";
import PortableText from "./portableText";

import * as styles from "../styles/components/cm-content-block.module.css";

const CMContentBlock = (props) => {

  const {
    heading,
    _rawDescription,
    image
  } = props;

  return (
    <div className={styles.root} id="hedgehog">
      <Container>
        <div className={styles.containerContent} data-sal="slide-up" data-sal-duration="800" data-sal-easing="ease-out">
          <div className={styles.flexGrid}>
            {heading && (
              <div className={cn(styles.col, styles.heading)}>
                <h2>
                  {heading}
                </h2>
              </div>
            )}
            {_rawDescription && (
              <div className={cn(styles.col, styles.text)}>
                <PortableText blocks={_rawDescription} />
              </div>
            )}
            {image && image.asset && (
              <div className={cn(styles.col, styles.image)}>
                <img
                  src={imageUrlFor(buildImageObj(image))
                    .auto("format")
                    .url()}
                  alt={image.alt}
                />
              </div>
            )}
          </div>
        </div>
        <div className={styles.containerTables} data-sal="slide-up" data-sal-duration="800" data-sal-easing="ease-out">
          <div className={styles.desktop}>
            <table>
              <thead>
                <tr>
                  <th>Pillar</th>
                  <th>Best in the world</th>
                  <th>Passion</th>
                  <th>Economic engine</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>HIA</td>
                  <td>Transformational development</td>
                  <td>Health & business longevity</td>
                  <td>Diversifying in areas of high expertise</td>
                </tr>
                <tr>
                  <td>Low tide</td>
                  <td>Creative office in vancouver; multi-family in Seattle</td>
                  <td>Designing neighborhoods for the 32-year old muse</td>
                  <td>Buying the right building, in the right location, at the right price</td>
                </tr>
                <tr>
                  <td>Wilson 5 foundation</td>
                  <td>Creating what otherwise would not occur</td>
                  <td>Getting people moving</td>
                  <td>Simplicity in governance</td>
                </tr>
                <tr>
                  <td>Active private equity</td>
                  <td>Technical athletic apparel</td>
                  <td>Athletics</td>
                  <td>Big bets and focus</td>
                </tr>
                <tr>
                  <td>Passive investments</td>
                  <td>Finding top private equity funds</td>
                  <td>2030 diversification strategy</td>
                  <td>Self-funding private equity in seven years</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className={styles.mobile}>
            <table>
              <tbody>
                <tr>
                  <th>Pillar</th>
                  <td>HIA</td>
                </tr>
                <tr>
                  <th>Best in the world</th>
                  <td>Transformational development</td>
                </tr>
                <tr>
                  <th>Passion</th>
                  <td>Health & business longevity</td>
                </tr>
                <tr>
                  <th>Economic engine</th>
                  <td>Diversifying in areas of high expertise</td>
                </tr>
              </tbody>
            </table>

            <table>
              <tbody>
                <tr>
                  <th>Pillar</th>
                  <td>Low tide</td>
                </tr>
                <tr>
                  <th>Best in the world</th>
                  <td>Creative office in vancouver; multi-family in Seattle</td>
                </tr>
                <tr>
                  <th>Passion</th>
                  <td>Designing neighborhoods for the 32-year old muse</td>
                </tr>
                <tr>
                  <th>Economic engine</th>
                  <td>Buying the right building, in the right location, at the right price</td>
                </tr>
              </tbody>
            </table>

            <table>
              <tbody>
                <tr>
                  <th>Pillar</th>
                  <td>Wilson 5 foundation</td>
                </tr>
                <tr>
                  <th>Best in the world</th>
                  <td>Creating what otherwise would not occur</td>
                </tr>
                <tr>
                  <th>Passion</th>
                  <td>Getting people moving</td>
                </tr>
                <tr>
                  <th>Economic engine</th>
                  <td>Simplicity in governance</td>
                </tr>
              </tbody>
            </table>

            <table>
              <tbody>
                <tr>
                  <th>Pillar</th>
                  <td>Active private equity</td>
                </tr>
                <tr>
                  <th>Best in the world</th>
                  <td>Technical athletic apparel</td>
                </tr>
                <tr>
                  <th>Passion</th>
                  <td>Athletics</td>
                </tr>
                <tr>
                  <th>Economic engine</th>
                  <td>Big bets and focus</td>
                </tr>
              </tbody>
            </table>

            <table>
              <tbody>
                <tr>
                  <th>Pillar</th>
                  <td>Passive investments</td>
                </tr>
                <tr>
                  <th>Best in the world</th>
                  <td>Finding top private equity funds</td>
                </tr>
                <tr>
                  <th>Passion</th>
                  <td>2030 diversification strategy</td>
                </tr>
                <tr>
                  <th>Economic engine</th>
                  <td>Self-funding private equity in seven years</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </Container>
    </div>
  );
}

export default CMContentBlock;
