import React, { useEffect, useContext } from "react";
import Container from "./container";
import { imageUrlFor } from "../lib/image-url";
import { buildImageObj } from "../lib/helpers";
import { Parallax, ParallaxContext } from 'react-scroll-parallax';

import * as styles from "../styles/components/featured-block.module.css";

const FeaturedBlock = (props) => {
  
  const {
    heading,
    description,
    image
  } = props;

  const context = useContext(ParallaxContext);

  useEffect(() => {
    if (context) {
      context.update();
    }
  });

  const onImageLoad = () => {
    if (context) {
      context.update();
    }
  }

  return (
    <div className={styles.root}>
      <div className={styles.text}>
        <Container>
          <h1>{heading}</h1>
          {description && (
            <p className="h3">{description}</p>
          )}
        </Container>
      </div>
      {image && image.asset && (
        <div className={styles.image}>
          <Parallax y={["68px", "-100px"]}>
            <img
              src={imageUrlFor(buildImageObj(image))
                .auto("format")
                .url()}
              alt={image.alt}
              onLoad={onImageLoad}
            />
          </Parallax>
        </div>
      )}
    </div>
  );
}

export default FeaturedBlock;
