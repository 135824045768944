import React, { useRef, useEffect } from "react";
import { graphql, StaticQuery } from "gatsby";
import Icon from "./icon";
import Container from "./container";
import { cn } from "../lib/helpers";

import * as styles from "../styles/components/cm-the-code.module.css";

export const query = graphql`
  query CodeQuery {
    code: allSanityCode(
      sort: { fields: [codeNumber], order: ASC }
      filter: { code: { ne: null } }
    ) {
      edges {
        node {
          id
          code
          codeNumber
        }
      }
    }
  }
`;

const TheCode = (props) => {

  const {
    heading,
    description,
    linkText,
    document
  } = props;

  const refs = useRef([]);
  
  let random;
  let prevRandom;

  function randomOnAction() {
    const codeLength = refs.current.length;
    random = Math.floor(Math.random() * codeLength);
    if(prevRandom !== undefined) {
      while(prevRandom === random) {
        random = Math.floor(Math.random() * codeLength);
      }
    }
    for (const ref of refs.current) {
      ref.className = styles.item;
    }
    refs.current[random].className = cn(styles.item, styles.active);
    // console.log(random+","+prevRandom);
    prevRandom = random;
  }

  // Similar to componentDidMount and componentDidUpdate:
  useEffect(() => {
    randomOnAction();
  });

  return (
    <StaticQuery
    query={query}
    render={data => (
      <div className={styles.root} id="code">
        <Container>

          {data && (
            <ul data-sal="slide-up" data-sal-duration="800" data-sal-easing="ease-out">
              {data.code.edges.map(({node: code}, i) => (
                <li key={code.id} className={styles.item} ref={el => refs.current[i] = el}>
                  <div className={styles.codeNumber}>
                    <span>{code.codeNumber}.</span>
                    <button 
                      className={styles.reset} 
                      onClick={randomOnAction}
                    >
                      <Icon symbol="reset" />
                      <span>Load Another</span>
                    </button>
                  </div>
                  <p>{code.code}</p>
                </li>
              ))}
            </ul>
          )}

          <div className={styles.containerContent} data-sal="slide-up" data-sal-duration="800" data-sal-easing="ease-out">
            {heading && (
              <h2>{heading}</h2>
            )}
            {description && (
              <p>{description}</p>
            )}
            {document && document.asset && (
              <p><a className={styles.more} href={document.asset.url + "/" + document.asset.originalFilename} target="_blank" rel="noreferrer">{linkText}<Icon symbol="doc" /></a></p>
            )}
          </div>
          
        </Container>
      </div>
    )} />
  );
}

export default TheCode;
