import React, { useRef, useEffect } from "react";
import Icon from "./icon";
import { imageUrlFor } from "../lib/image-url";
import { buildImageObj } from "../lib/helpers";

import * as styles from "../styles/components/info-box.module.css";

const InfoBox = (props) => {

  const {
    cover,
    infoBoxHeading,
    description
  } = props;

  const infoBoxRef = useRef(null);

  function handleClick() {
    infoBoxRef.current.classList.toggle(styles.active);
  }

  function useOutsideInfoBox(ref) {
    useEffect(() => {
        
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          infoBoxRef.current.classList.remove(styles.active);
        }
      }

      // Bind the event listener
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  }

  useOutsideInfoBox(infoBoxRef);

  return (
    <div className={styles.root} ref={infoBoxRef}>
      <div className={styles.hiddenContent}>
        <div className={styles.containerContent}>
          {cover && cover.asset && (
            <img
              src={imageUrlFor(buildImageObj(cover))
                .auto("format")
                .url()}
              alt={cover.alt}
            />
          )}
          <h4 className={styles.heading}>
            {infoBoxHeading ? infoBoxHeading : "Why this book?" }
          </h4>
          {description && (
            <p>{description}</p>
          )}
        </div>
      </div>
      <button 
        className={styles.btnInfo}
        onClick={handleClick}
      >
        <Icon symbol="question" />
        <span>More Information</span>
      </button>
    </div>
  );
}

export default InfoBox;
