import React from "react";
import { graphql } from "gatsby";
import GraphQLErrorList from "../components/graphql-error-list";
import Seo from "../components/seo";
import Layout from "../containers/layout";

import FeaturedBlock from "../components/featured-block";
import Media from "../components/cm-media";
import Library from "../components/cm-library";
import TheCode from "../components/cm-the-code";
import CMFeaturedBlock from "../components/cm-featured-block";
import ContentBlock from "../components/cm-content-block";

export const query = graphql`
  fragment SanityImage on SanityMainImage {
    crop {
      _key
      _type
      top
      bottom
      left
      right
    }
    hotspot {
      _key
      _type
      x
      y
      height
      width
    }
    asset {
      _id
    }
  }

  query CultureMediaPageQuery {
    cm: sanityCultureMedia {
      featuredBlock {
        heading
        description
        image {
          ...SanityImage
          alt
        }
      }
      cmMedia {
        heading
        subheading
        featuredMedia {
          _key
          media {
            title
            logo {
              ...SanityImage
              alt
            }
            quote
            externalLink {
              text
              linkUrl
            }
            publishedOn
            category {
              title
            }
            mediaFile {
              asset {
                url
              }
            }
            youtube {
              embedId
              startTime
              endTime
            }
            cover {
              ...SanityImage
              alt
            }
            bigCover
          }
        }
        link {
          text
          externalContent
          linkUrl
        }
      }
      cmLibrary {
        _rawHeading
        category
        featuredLibraryItems {
          _key
          libraryItem {
            title
            infoBoxHeading
            description
            cover {
              ...SanityImage
              alt
            }
          }
        }
        link {
          text
          externalContent
          linkUrl
        }
        readingNowHeading
        readingNowBooks {
          _key
          title
          author
          description
          cover {
            ...SanityImage
            alt
          }
        }
      }
      cmTheCode {
        heading
        description
        linkText
        document {
          asset {
            url
            originalFilename
          }
        }
      }
      cmFeaturedBlock {
        heading
        subheading
        highlightedText
        description
        image {
          ...SanityImage
          alt
        }
        linkText
        document {
          asset {
            url
            originalFilename
          }
        }
      }
      cmContentBlock {
        heading
        _rawDescription
        image {
          ...SanityImage
          alt
        }
      }
    }
  }
`;

const CultureMediaPage = (props) => {
  const { data, errors } = props;

  if (errors) {
    return (
      <Layout>
        <GraphQLErrorList errors={errors} />
      </Layout>
    );
  }

  const cm = (data || {}).cm;

  return (
    <Layout>
      <Seo
        title={cm.featuredBlock.heading}
        description={cm.featuredBlock.description}
      />

      {cm.featuredBlock && (
        <FeaturedBlock {...cm.featuredBlock} />
      )}

      {cm.cmMedia && (
        <Media {...cm.cmMedia} />
      )}

      {cm.cmLibrary && (
        <Library {...cm.cmLibrary} />
      )}

      {cm.cmTheCode && (
        <TheCode {...cm.cmTheCode} />
      )}

      {cm.cmFeaturedBlock && (
        <CMFeaturedBlock {...cm.cmFeaturedBlock} />
      )}

      {cm.cmContentBlock && (
        <ContentBlock {...cm.cmContentBlock} />
      )}
    </Layout>
  );
};

export default CultureMediaPage;
