import React from "react";
import { Link } from "gatsby";
import FeaturedMediaList from "./featured-media-list";
import Container from "./container";

import * as styles from "../styles/components/cm-media.module.css";

const Media = (props) => {

  const {
    heading,
    subheading,
    featuredMedia,
    link
  } = props;

  return (
    <div className={styles.root} id="media">
      <Container>
        <div className={styles.containerContent}>
          {heading && (
            <h2 className={styles.heading} data-sal="slide-up" data-sal-duration="800" data-sal-easing="ease-out">{heading}</h2>
          )}
          {subheading && (
            <h3 className={styles.subheading} data-sal="slide-up" data-sal-duration="800" data-sal-easing="ease-out">{subheading}</h3>
          )}
          {featuredMedia && <FeaturedMediaList items={featuredMedia} />}
          {link && link.linkUrl && (
            <>
            {link.externalContent ? 
              (
                <a className={styles.more} href={link.linkUrl} target="_blank" rel="noreferrer" data-sal="slide-up" data-sal-duration="800" data-sal-easing="ease-out">{link.text}</a>
              ) : (
                <Link className={styles.more} to={`${link.linkUrl}`} data-sal="slide-up" data-sal-duration="800" data-sal-easing="ease-out">{link.text}</Link> 
              )
            }
            </>
          )}
        </div>
      </Container>
    </div>
  );
}

export default Media;
