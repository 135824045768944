import React from "react";
import { Link } from "gatsby";
import FeaturedLibraryList from "./featured-library-list";
import ReadingNowBooks from "./reading-now-books";
import Container from "./container";
import PortableText from "./portableText";
import { cn } from "../lib/helpers";

import * as styles from "../styles/components/cm-library.module.css";

const Library = (props) => {

  const {
    _rawHeading,
    category,
    featuredLibraryItems,
    link,
    readingNowHeading,
    readingNowBooks
  } = props;

  return (
    <div className={styles.root} id="library">
      <Container>
        <div className={styles.containerContent}>
          {_rawHeading && (
            <h2 className={styles.heading} data-sal="slide-up" data-sal-duration="800" data-sal-easing="ease-out">
              <PortableText blocks={_rawHeading} />
            </h2>
          )}
          {featuredLibraryItems && <FeaturedLibraryList category={category} items={featuredLibraryItems} />}
          {link && link.linkUrl && (
            <div className={styles.linkWrap} data-sal="slide-up" data-sal-duration="800" data-sal-easing="ease-out">
              {link.externalContent ? 
                (
                  <a className={styles.more} href={link.linkUrl} target="_blank" rel="noreferrer">{link.text}</a>
                ) : (
                  <Link className={styles.more} to={`${link.linkUrl}`}>{link.text}</Link> 
                )
              }
            </div>
          )}
          {readingNowHeading && (
            <h3 className={cn("h4", styles.sectionTitle)} data-sal="slide-up" data-sal-duration="800" data-sal-easing="ease-out">
              {readingNowHeading}
            </h3>
          )}
          {readingNowBooks && <ReadingNowBooks items={readingNowBooks} />}
        </div>
      </Container>
    </div>
  );
}

export default Library;
