import React from "react";
import { imageUrlFor } from "../lib/image-url";
import { buildImageObj } from "../lib/helpers";

import * as styles from "../styles/components/reading-now-books.module.css";

function ReadingNowBooks({ items }) {
  return (
    <div className={styles.root}>
      {items.map(({ _key, title, author, description, cover }) => {
        return (
          <div key={_key} className={styles.item} data-sal="slide-up" data-sal-duration="800" data-sal-easing="ease-out">
            {title && (
              <div className={styles.titleWrap}>
                <h4 className={styles.title}>{title}</h4>
                {cover && cover.asset && (
                  <div className={styles.image}>
                    <img
                      src={imageUrlFor(buildImageObj(cover))
                        .auto("format")
                        .url()}
                      alt={cover.alt}
                    />
                  </div>
                )}
              </div>
            )}
            {author && (
              <div className={styles.author}>By {author}</div>
            )}
            {description && (
              <p>{description}</p>
            )}
          </div>
        );
      })}
    </div>
  );
}

export default ReadingNowBooks;
