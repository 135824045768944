import React, { useRef } from "react";
import Icon from "./icon";
import { format } from "date-fns";
import { imageUrlFor } from "../lib/image-url";
import { buildImageObj } from "../lib/helpers";
import { cn } from "../lib/helpers";

import * as styles from "../styles/components/media-details.module.css";

function FeaturedMediaDetails(props) {

  const {
    title,
    logo,
    quote,
    externalLink,
    publishedOn,
    category,
    mediaFile,
    youtube,
    cover,
    bigCover,
    _key
  } = props;

  const mediaRef = useRef(null);

  function handleStopAllMedia() {
    let videos = document.getElementsByClassName(styles.video);
    let audios = document.getElementsByClassName(styles.audio);

    for (const video of videos) {
      video.setAttribute('src', video.getAttribute('src').replace('&autoplay=1', ''));
      video.parentElement.classList.remove(styles.active);
    }

    for (const audio of audios) {
      audio.pause();
      audio.currentTime = 0;
      audio.parentElement.classList.remove(styles.active);
    }

  }

  function handlePlayAudio() {
    handleStopAllMedia();
    mediaRef.current.parentElement.classList.add(styles.active);
    mediaRef.current.play();
  }

  function handlePlayVideo() {
    handleStopAllMedia();
    mediaRef.current.parentElement.classList.add(styles.active);
    mediaRef.current.src = mediaRef.current.src + '&autoplay=1';
  }

  return (
    <div key={_key} className={cn(styles.root, bigCover && styles.big)} data-sal="slide-up" data-sal-duration="800" data-sal-easing="ease-out">
      <div className={styles.containerContent}>
        {(publishedOn || category) && (
          <div className={styles.meta}>
            {publishedOn && (
              <span>{format(new Date(publishedOn).setDate(new Date(publishedOn).getDate() + 1), "dd.MM.yyyy")}</span>
            )}
            {category && (
              <span>{category.title}</span>
            )}
          </div>
        )}
        {((youtube && youtube.embedId) || (mediaFile && mediaFile.asset) || (externalLink && externalLink.linkUrl) || (cover && cover.asset)) && (
          <div className={styles.media}>
            {cover && cover.asset && (
              <div className={styles.cover}>
                <img
                  src={imageUrlFor(buildImageObj(cover))
                    .auto("format")
                    .url()}
                  alt={cover.alt}
                />
              </div>
            )}

            {(category && ((youtube && youtube.embedId && category.title === "Video") || (mediaFile && mediaFile.asset && category.title === "Podcast"))) ? (
              <div className={styles.action} onClick={category.title === "Video" ? handlePlayVideo : handlePlayAudio} onKeyDown={category.title === "Video" ? handlePlayVideo : handlePlayAudio} role="button" tabIndex={0}>
                <Icon symbol="play" />
              </div>
            ) : (
              <>
                {(externalLink && externalLink.linkUrl) ? (
                  <a className={styles.action} href={externalLink.linkUrl} target="_blank" rel="noreferrer"><Icon symbol="external" /><span className="hidden">Media</span></a>
                ) : (
                  <>
                    {mediaFile && mediaFile.asset && (
                      <a className={styles.action} href={mediaFile.asset.url} target="_blank" rel="noreferrer"><Icon symbol="external" /><span className="hidden">Media</span></a>
                    )}
                  </>
                )}
              </>
            )}

            {(category && ((youtube && youtube.embedId && category.title === "Video") || (mediaFile && mediaFile.asset && category.title === "Podcast"))) && (
              <div className={styles.player}>
                {category.title === "Video" ? (
                  <iframe title={title} frameborder="0" scrolling="no" marginheight="0" marginwidth="0" width="560" height="315" type="text/html" src={"https://www.youtube.com/embed/"+youtube.embedId+"?iv_load_policy=3&rel=0&cc_load_policy=0&start="+youtube.startTime+"&end="+youtube.endTime} allowfullscreen="" allow="autoplay" className={styles.video} ref={mediaRef}></iframe>
                ) : (
                  <audio controls ref={mediaRef} className={styles.audio}>
                    <source src={mediaFile.asset.url} />
                  </audio>
                )}
              </div>
            )}
          </div>
        )}
        {title && (
          <div className={styles.content}>
            {logo && logo.asset && (
              <img
                src={imageUrlFor(buildImageObj(logo))
                  .auto("format")
                  .url()}
                alt={logo.alt}
              />
            )}
            <h4 className={styles.title}>{title}</h4>
            {quote && (
              <p>"{quote}"</p>
            )}
            {((mediaFile && mediaFile.asset) || (externalLink && externalLink.linkUrl)) && (
              <>
                {(externalLink && externalLink.linkUrl) ? (
                  <a className={styles.more} href={externalLink.linkUrl} target="_blank" rel="noreferrer">{externalLink.text}<Icon symbol="external" /></a>
                ) : (
                  <a className={styles.more} href={mediaFile.asset.url} target="_blank" rel="noreferrer">{externalLink.text}<Icon symbol="external" /></a>
                )}
              </>
            )}
          </div>
        )}
      </div>
    </div>
  );
}

export default FeaturedMediaDetails;
