// extracted by mini-css-extract-plugin
export const root = "cm-content-block-module--root--31P01";
export const containerContent = "cm-content-block-module--containerContent--1GxD7";
export const flexGrid = "cm-content-block-module--flexGrid--3y-G_";
export const col = "cm-content-block-module--col--35SVl";
export const heading = "cm-content-block-module--heading--kjASk";
export const text = "cm-content-block-module--text--3j51S";
export const image = "cm-content-block-module--image--ePJx_";
export const containerTables = "cm-content-block-module--containerTables--3NpLo";
export const desktop = "cm-content-block-module--desktop--36urH";
export const mobile = "cm-content-block-module--mobile--1Nrt0";