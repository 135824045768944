import React from "react";
import { cn } from "../lib/helpers";
import InfoBox from "./info-box";

import * as styles from "../styles/components/library-list.module.css";

function FeaturedLibraryList({ category, items }) {
  return (
    <div className={styles.root} data-sal="slide-up" data-sal-duration="800" data-sal-easing="ease-out">
      <div className={styles.group}>
        {category && (
          <h3 className={cn("h4", styles.sectionTitle)}>{category}</h3>
        )}
        <ul>
          {items.map(({ libraryItem, _key }) => {
            return (
              <li key={_key} className={styles.item}>
                {libraryItem.title && (
                  <h4 className={cn("h3", styles.itemTitle)}>{libraryItem.title}</h4>
                )}
                {(libraryItem.infoBoxHeading || libraryItem.description || (libraryItem.cover && libraryItem.cover.asset)) && (
                  <InfoBox {...libraryItem} />
                )}
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  );
}

export default FeaturedLibraryList;
