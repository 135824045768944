import React from "react";
import FeaturedMediaDetails from "./featured-media-details";

import * as styles from "../styles/components/media-list.module.css";

function FeaturedMediaList({ items }) {

  return (
    <div className={styles.root}>
      {items.map(({ media, _key }) => {
        return (
          <FeaturedMediaDetails {...media} key={_key} />
        );
      })}
    </div>
  );
}

export default FeaturedMediaList;
