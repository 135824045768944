import React, { useEffect, useContext } from "react";
import Icon from "./icon";
import Container from "./container";
import { imageUrlFor } from "../lib/image-url";
import { buildImageObj } from "../lib/helpers";
import { Parallax, ParallaxContext } from 'react-scroll-parallax';

import * as styles from "../styles/components/cm-featured-block.module.css";

const CMFeaturedBlock = (props) => {

  const {
    heading,
    subheading,
    highlightedText,
    description,
    image,
    linkText,
    document
  } = props;

  const context = useContext(ParallaxContext);

  useEffect(() => {
    if (context) {
      context.update();
    }
  });

  const onImageLoad = () => {
    if (context) {
      context.update();
    }
  }

  return (
    <div className={styles.root} id="manifesto">
      {image && image.asset && (
        <div className={styles.image} data-sal="slide-up" data-sal-duration="800" data-sal-easing="ease-out">
          <Parallax y={["50px", "-100px"]}>
            <img
              src={imageUrlFor(buildImageObj(image))
                .auto("format")
                .url()}
              alt={image.alt}
              onLoad={onImageLoad}
            />
          </Parallax>
        </div>
      )}
      {(heading || description) && (
        <div className={styles.text} data-sal="slide-up" data-sal-duration="800" data-sal-easing="ease-out">
          <Container>
            <div className={styles.headingWrap}>
              {subheading && (
                <div className={styles.subheading}>
                  {subheading}
                </div>
              )}
              {heading && (
                <h2 className={styles.heading}>
                  {heading}
                </h2>
              )}
            </div>
            {highlightedText && (
              <div className={styles.containerContent}>
                <p>
                  {highlightedText}
                </p>
              </div>
            )}
            {(description || (document && document.asset.url)) && (
              <div className={styles.containerContentS}>
                {description && (
                  <p>
                    {description}
                  </p>
                )}
                {document && document.asset.url && (
                  <a className={styles.more} href={document.asset.url + "/" + document.asset.originalFilename} target="_blank" rel="noreferrer">{linkText}<Icon symbol="doc" /></a>
                )}
              </div>
            )}
          </Container>
        </div>
      )}
    </div>
  );
}

export default CMFeaturedBlock;
